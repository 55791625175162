.show-list-item {
    display: grid;
    color: black;
    grid-template-rows: 2fr 1fr;
    grid-template-columns: 2fr 1fr;
    gap: 0 0;
    height: 100%;
    margin: auto;
    grid-template-areas:
        "poster title"
        "poster score"
}

.show-list-item .poster {
    grid-area: poster;
}

.show-list-item .poster img {
    max-height: 200px;
    max-width: 150px;
}

.show-list-item .title {
    grid-area: title;
    text-align: center;
    display: flex;
    align-items: center;
}

.show-list-item .year {
    grid-area: year;
}

.show-list-item .score {
    grid-area: score;
}

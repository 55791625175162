.show {
    display: grid;
    grid-template-rows: 1fr 1fr 2fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 0;
    height: 100%;
    text-align: center;
    margin: auto;
    grid-template-areas:
        "poster title title"
        "poster rating hrs"
        "poster reviews reviews";
}

.show .poster {
    grid-area: poster;
    margin: auto;
}

.show .poster img {
    max-height: 600px;
    max-width: min(50vw, 400px);
}

.show .title {
    grid-area: title;
    margin: auto;
}

.show .rating {
    grid-area: rating;
    margin: auto;
}

.show .hrs {
    grid-area: hrs;
    margin: auto;
}

.show .reviews {
    grid-area: reviews;
}

.show .reviews a {
    color: black;
}

.show .reviews svg {
    height: 14px;
}

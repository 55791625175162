.search-bar #search-input {
    background: dimgray url(../assets/looking-glass.svg) no-repeat 5px 2px;
    width: 300px;
    display: block;
    border: none;
    border-radius: 25px;
    color: white;
    margin: 11px;
    padding: 5px 20px 5px 30px;
}

.search-bar #search-results {
    position: absolute;
    z-index: 1;
    color: white;
}

.search-bar #search-results ul {
    display: block;
}

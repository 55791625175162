.about .description {
    display: grid;
    grid-area: text;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "summary summary"
        "rating hrs";
    gap: 0 0;
    height: 100%;
    margin: auto;
}

.about .summary {
    grid-area: summary;
    text-align: left;
    margin-left: 35px;
    margin-right: 35px;
}

.about .rating {
    grid-area: rating;
    text-align: center;
    margin: auto;
}

.about .hrs {
    grid-area: hrs;
    text-align: center;
    margin: auto;
}

.about .examples {
    display: flex;
}
